import { useContext, useEffect, useRef, useState } from "react";
import { Context, SET_VALUES } from "../store";
import { STATUS } from "../enum";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import { debugKey } from "../utils/debug";
const MySwal = withReactContent(Swal)

const AmountForm = ({ requestPayment, payable = true }) => {

  const min = localStorage.getItem('min') || 100;
  const max = localStorage.getItem('max') || 50000;

  const [amount, setAmount] = useState<"" | number>(0);

  const [state, dispatch] = useContext(Context);

  const amountInput: any = useRef<HTMLInputElement>(null)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();


    const data = Object.fromEntries(
      new FormData(event.target as HTMLFormElement)
    );
    const amount = Number(data.amount);
    debugKey();
    setAmount(amount);
    requestPayment(amount);
  };

  const setQickAmount = (amount: number) => {
    amountInput.current.focus();
    amountInput.current.value = amount;
  };

  const handleReset = async () => {

    let { isConfirmed } = await MySwal.fire({
      icon: 'warning',
      title: "ยืนยันการทำรายการ",
      text: "ต้องการยกเลิกรายการฝากหรือไม่",
      showDenyButton: true,
      confirmButtonText: `ใช่`,
      denyButtonText: `ไม่`,
    });

    if (isConfirmed) {

      dispatch({
        type: SET_VALUES,
        payload: {
          status: STATUS.WAIT,
        },
      });

      localStorage.removeItem("paymentInfo");
    }

  };

  return (
    <div>
      <div className="rounded-lg p-3">
        <div>
          {state.status == STATUS.WAIT ? (
            <p className="text-gray-custom font-bold text-md">
              ฝากเงิน
            </p>
          ) : (
            <p className="text-gray-custom font-bold text-md">
              ฝากเงิน
            </p>
          )}
        </div>

        {state.status == STATUS.WAIT ? (

          <form onSubmit={onSubmit}>
            <div className="flex flex-row">
              <div className="basis-2/3 p-1">
                <input ref={amountInput} type="number" name="amount" placeholder={`ขั้นต่ำ ${min} บาท`} min={min} max={max} className="input input-bordered w-full max-w-xs bg-white text-black" />
              </div>
              <div className="basis-1/3 p-1">
                <button type="button" className="border-2 border-blue-custom rounded-lg w-full rounded-lg font-thin text-lg p-2" onClick={() => setQickAmount(0)}> เลือกเอง </button>
              </div>
            </div>


            <div className="grid grid-cols-3 gap-2 font-bold px-1">
              {
                Number(localStorage.getItem('min')) > 100 ? (
                  <button type="button" className="col-span-3 bg-white border-2 border-blue-custom rounded-lg text-lg p-2" onClick={() => setQickAmount(Number(localStorage.getItem('min')))}> {Number(localStorage.getItem('min'))} ฿ </button>
                ) : (
                  <button type="button" className="col-span-3 bg-white border-2 border-blue-custom rounded-lg text-lg p-2" onClick={() => setQickAmount(100)}> 100 ฿ </button>
                )
              }
              <button type="button" className="bg-white border-2 border-blue-custom rounded-lg text-lg p-2" onClick={() => setQickAmount(200)}> 200 ฿ </button>
              <button type="button" className="bg-white border-2 border-blue-custom rounded-lg text-lg p-2" onClick={() => setQickAmount(500)}> 500 ฿ </button>
              <button type="button" className="bg-white border-2 border-blue-custom rounded-lg text-lg p-2" onClick={() => setQickAmount(1000)}> 1000 ฿ </button>


              <button className={'col-span-3 border-2 text-white rounded-lg text-lg p-2 ' + (payable ? 'bg-green-custom' : 'bg-gray-custom text-gray-custom')}>
                เติมเงิน
              </button>
            </div>
          </form>

        ) : (
          <div className="flex flex-row">
            <div className="basis-1/2 p-1">
              <p className='text-xl m-3'>฿ {amount || state.amount} บาท</p>
            </div>
            <div className="basis-1/2 p-1">
              <button className="bg-green-custom text-white w-full rounded-lg font-thin text-lg p-2" onClick={handleReset}>
                แก้ไข
              </button>
            </div>
          </div>
        )}
      </div>


    </div >
  );
};
export default AmountForm;
