import { useContext, useEffect, useState } from "react";
import { Context } from "../store";

import { ExternalLink } from "react-external-link";



const Timeout = () => {
  const [state, dispatch] = useContext(Context);

  const [redirectUrl, setRedirect] = useState<string>("");

  /*
    ลบ hash เพื่อป้องกันให้กลับไปขอ qr ใหม่
  */
  useEffect(() => {
    setRedirect(state.meta.resultURL);
    setTimeout(() => {
      window.location.href = state.meta.resultURL;
    },10000);
    localStorage.clear();
  });


  return (
    <div className="container mx-auto text-center">
      <div className="pt-[69px]">
        <img
          className="object-contain h-32 mx-auto"
          src="https://miro.medium.com/max/1400/1*nueyBV0RNEpETYMKpsYWhA.png"
          alt="true-money-wallet-logo"
        />
      </div>
      <div className="text-black font-semibold text-[22px] pt-8">
        QR Code หมดอายุ หากท่านทำรายการแล้วแต่ยอดเงินไม่เข้า กรุณาติดต่อ พนักงาน
      </div>
    </div>
  );
};
export default Timeout;
