import { useContext, useEffect, useState, useRef } from "react";
import { checkPayment } from "../common/api/payment";
import Swal from "sweetalert2";
import { Context, SET_VALUES } from "../store";
import Countdown, { zeroPad } from "react-countdown";
import { STATUS } from "../enum";
import dayjs from "dayjs";

import downloadjs from "downloadjs";
import html2canvas from "html2canvas";

import "./qrcode.style.scss";
import { AxiosError } from "axios";

import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)


const QRCodeComponent = () => {
  const [isChecking, setChecking] = useState<boolean>(false);

  const [state, dispatch] = useContext(Context);

  const paymentInfo = JSON.parse(
    (localStorage.getItem("paymentInfo") as string) || "{}"
  );

  const isError = async () => {
    await Swal.fire({
      icon: "error",
      title: `เกิดข้อผิดพลาด ติดต่อเจ้าหน้าที่ ${localStorage.getItem("x-debug-key")}`,
      showConfirmButton: false,
      timer: 1500,
    });
    window.location.href = "/";
  };

  const isExpire = async () => {
    await Swal.fire({
      icon: "error",
      title: "หมดเวลาการชำระเงิน",
      text: `หากท่านทำรายการแล้วแต่ยอดเงินไม่เข้า กรุณาติดต่อ พนักงาน ${localStorage.getItem("x-debug-key")}`,
      showConfirmButton: false,
      timer: 1500,
    });
    window.location.href = "/";
    localStorage.clear();
  };

  const countdownRendered = ({ completed }) => {

    if (completed) {
      isExpire();
    } else {
      (async () => {
        try {
          const response = await checkPayment({
            id: paymentInfo.id,
          });

          if (["successed", "sended"].includes(response.data.data.status)) {
            // รอทำ enum ถ้า ขยัน
            dispatch({
              type: SET_VALUES,
              payload: {
                status: STATUS.SUCCESS,
              },
            });
          }
        } catch (e: any) {
          if (e instanceof AxiosError) {
            /* ปิด error ตอน download ไฟล์ */
            if (e.response?.status as number > 0) {
              isError();
            }
          }
        }
      })();

      return dayjs(paymentInfo.expiredDate)
        .format(" วันที่ DD เวลา HH:mm น")
        .toString();
    }
  };

  const QRCodeGenerator = ({ data }) => {

    const showWarning = async () => {
      const idiotTime = localStorage.getItem("idiotTime");

      if (idiotTime == null || Number(idiotTime) < Math.round(new Date().getTime() / 1000)) {


        let { isDenied, isConfirmed } = await MySwal.fire({
          icon: 'warning',
          title: "ห้ามใช้ QR ฝากเงินซ้ำ",
          html: "หากลูกค้าใช้ QR ฝากเงินซ้ำ <br> จะเกิดปัญหา ฝากเงินช้า หรือ ฝากไม่เข้า",
          showDenyButton: true,
          confirmButtonText: `ฉันไม่เข้าใจ`,
          denyButtonText: `ฉันเข้าใจ`
        });

        if (isConfirmed == true) {
          await showWarning();
        }

        localStorage.setItem("idiotTime", (Math.round((new Date().getTime() / 1000) + 600)).toString());

      }
    }

    const qrCodeRef = useRef<HTMLDivElement | null>(null);

    (async () => {
      await showWarning();
    })();

    const handleCaptureClick = async () => {
      const element = qrCodeRef.current;
      if (element) {
        const canvas = await html2canvas(element);
        const dataURL = canvas.toDataURL("image/png");

        const date = new Date();

        const fileName = `QR_${date.getDate()}/${date.getMonth() + 1
          }_${date.getHours()}:${date.getMinutes()}.png`;
        downloadjs(dataURL, fileName, "image/png");
      }
    };

    return (
      <div>
        <div ref={qrCodeRef} className="text-center" onClick={handleCaptureClick}>
          <div>
            {
              paymentInfo.paymentMethod === 'TRUEWALLET'
                ? <img src="./static/logo/truemoney.svg" className="rounded-lg px-6" />
                : <img src="./static/logo/template.png" className="rounded-lg" />
            }

            <p className="text-gray-custom text-xs">
              {paymentInfo.merchantRef} [{paymentInfo.payAmount}]
            </p>
          </div>

          <div className="pt-1">
            <p className="text-red-600 font-bold blink">
              ห้ามใช้ QR Code จ่ายเงินซ้ำ
            </p>
            <img
              src={data || "https://dummyimage.com/200/000000/fff.png&text=QR"}
              className="mx-auto"
            />
          </div>

          <div className="pb-1">
            <p className="text-gray-custom mt-3"> ห้ามทำรายการหลังจากเวลา </p>
            <p className="text-red-600 font-bold blink">
              <Countdown
                zeroPadTime={2}
                date={paymentInfo.expiredDate}
                renderer={countdownRendered}
              />
            </p>
          </div>
        </div>

        <div className="mx-3 mb-4 mt-2">
          <button
            type="button"
            className="w-full py-4 border-2 border-blue-custom font-semibold rounded-lg"
            onClick={handleCaptureClick}
          >
            กดเพื่อ แคปจอ
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto w-96 rounded-lg" id="qr-card">
      <div className="mx-auto text-center">
        <QRCodeGenerator data={paymentInfo.qrCode} />
      </div>
    </div>
  );
};

export default QRCodeComponent;
