import { createContext, useReducer } from "react";
import { STATUS } from "./enum";
import { stat } from "fs";
import { Buffer } from 'buffer';


const parseJwt = (token) => {
  try {
    const payload = Buffer.from(token.split(".")[1], 'base64').toString();
    return JSON.parse(payload);
  } catch (e) {
    return null;
  }
};

export const SET_VALUES = "SET_VALUES";
export const SET_INITIAL = "SET_INITIAL";

export const Reducer = (state, action) => {
  switch (action.type) {
    case SET_VALUES:

      if (action.payload?.status) {
        localStorage.setItem("status", action.payload.status as STATUS);
      }

      if (action.payload?.amount) {
        localStorage.setItem(
          "amount",
          action.payload.amount
        );
      }

      if (action.payload?.paymentMethod) {
        localStorage.setItem(
          "paymentMethod",
          action.payload.paymentMethod
        );
      }

      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const initialState: any = () => {
  let tokenCookie: string = localStorage.getItem("hash") || "";
  let statusCookie: STATUS =
    (localStorage.getItem("status") as STATUS) || STATUS.WAIT;
  let decoded = parseJwt(tokenCookie);

  try {
    if (tokenCookie == "") throw new Error(STATUS.INVALID);
    if (decoded.exp < Date.now() / 1000) throw new Error(STATUS.TIMEOUT);

  } catch (e: any) {
    statusCookie = e.message;
  }

  return {
    meta: decoded || {},
    token: tokenCookie || null,
    status: statusCookie || STATUS.INVALID,
    amount: localStorage.getItem("amount") || 0,
    paymentMethod: localStorage.getItem("paymentMethod") || 'QR',
  };
};

export const Context = createContext(initialState());

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState());

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export default Store;
