import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from "react-router";
import { STATUS } from "../enum";
import { Buffer } from 'buffer';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

/*
  เดียวมา refactor ขี้เกียจ 555
*/
const parseJwt = (token) => {
  try {
    const payload = Buffer.from(token.split(".")[1], 'base64').toString();
    return JSON.parse(payload);
  } catch (e) {
    return null;
  }
};

class invalidToken extends Error {
  constructor(message: string) {
    super(message);
    this.name = "invalidToken";
  }
}

const Grant = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token: string = useParams<{ token: string }>().token || "";
  const min: string = searchParams.get('min') || "100";
  const max: string = searchParams.get('max') || "50000";
  const paymentMethod = searchParams.get('paymentMethod') || 'QR';

  const setToken = () => {
    try {
      const parseToken = parseJwt(token);

      localStorage.setItem("hash", token as string);
      localStorage.setItem("status", STATUS.WAIT);

      localStorage.setItem("min", min as string);
      localStorage.setItem("max", max as string);
      localStorage.setItem("paymentMethod", paymentMethod);
      localStorage.setItem("provider", parseToken.provider);
      localStorage.setItem("uid", parseToken.uid);

      localStorage.setItem("paymentInfo", "{}");
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    (async () => {
      const checkSession = localStorage.getItem("hash") || "";
      const parseCheckSession = parseJwt(checkSession);
      const parseToken = parseJwt(token);
      try {

        if (checkSession === "") throw new invalidToken('invalid token');

        if (checkSession !== token) throw new invalidToken('missmatch secret');

        if (parseCheckSession.exp < ((Date.now() / 1000) + (60 * 5))) throw new invalidToken('token expired');

        // if (localStorage.getItem("status") == STATUS.WAIT) {
        //   throw new Error('force redirect');
        // }

        if (localStorage.getItem("status") === STATUS.PENDING) {

          let { isDenied } = await MySwal.fire({
            icon: 'warning',
            title: "คุณมีรายการค้าง",
            text: "ต้องการฝากรายการเดิมหรือไม่",
            showDenyButton: true,
            confirmButtonText: `ใช่`,
            denyButtonText: `ไม่`,
          });

          if (isDenied) {
            throw new invalidToken('reset token');
          }

          // force redirect to same qr
          throw new Error('force redirect');
        }

        // ถ้าไม่มีการ set token ให้ navigate back
        navigate(-1);
        localStorage.clear();

      } catch (e: any) {
        console.error(e);
        if (e.name == 'invalidToken') {
          setToken();
        }

        console.log({ parseToken })
        if (parseToken?.provider === 'peer2pay') {
          navigate("/peer2pay");
        } else {
          navigate("/payment");
        }
      }
    })();


  }, []);

  return <div></div>;
};
export default Grant;
