import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Form from "./page/form.component";
import Success from "./page/success.component";
import Grant from "./page/grant.component";
import { Context } from "./store";
import Protected from "./protected";
import Store from "./store";
import Timeout from "./page/timeout.component";
import Invalid from "./page/invalid.component";
import Peer2pay from "./page/peer2pay.component";

const Router = () => {
  const state = useContext(Context);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Store>
            {" "}
            <Protected />{" "}
          </Store>
        }
      >
        <Route path={"/peer2pay"} element={<Peer2pay />} />
        <Route path={"/payment"} element={<Form />} />
        <Route path={"/success"} element={<Success />} />
        <Route path={"/timeout"} element={<Timeout />} />
      </Route>
      <Route path={"/grant/:token"} element={<Grant />} />

      <Route path="*" element={<Invalid />} />
    </Routes>
  );
};
export default Router;
