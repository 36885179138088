import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import AmountForm from "../component/amount.form.component";
import { requestPayment } from "../common/api/payment";
import loading from "../utils/loading";

import { Context, SET_VALUES } from "../store";
import { STATUS } from "../enum";

const Peer2pay = () => {
  const navigate = useNavigate();

  const [amount, setAmount] = useState<"" | number>(0);
  const [uid, setUid] = useState<string>(localStorage.getItem('uid') ?? '');
  const [payable, setPayable] = useState(false);
  const [isDone, setDone] = useState<boolean>(false);

  const [state, dispatch] = useContext(Context);

  /*
      สร้างรายการฝากเงิน
  */
  useEffect(() => {
    const reqeust = async () => {
      if (amount == "") return; // ค่าเริ่มต้น ไม่ต้องทำอะไร

      if (amount >= Number(localStorage.getItem("min")) && amount <= Number(localStorage.getItem("max"))) {
        // ถ้าใส่จำนวนเงินค่อยรัน

        setAmount(0); // rollback

        loading(); //swal loading

        try {
          let res = await requestPayment({
            amount: Number(amount),
            uid
          });

          window.location.href = res.data.data.qrCode

          dispatch({
            type: SET_VALUES,
            payload: {
              status: STATUS.PENDING,
              amount: Number(amount),
            },
          });

          localStorage.setItem("paymentInfo", JSON.stringify(res.data.data));

          Swal.close(); // close loading
        } catch (e: any) {
          Swal.fire({
            icon: "error",
            title: "ระบบเติมเงินมีปัญหา กรุณาทำรายการใหม่",
            text: e.message || "",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: `กรุณากรอกจำนวนเงิน ${localStorage.getItem("min")} - ${localStorage.getItem("max")} บาท`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };

    reqeust()
      .then()
      .catch((e) => {
        console.error(e);
      });
  }, [amount]);

  /*
    เช็คว่าการเติมเงินเสร็จสิ้นหรือยัง
  */
  useEffect(() => {
    if (isDone) {
      navigate("/success");
    }
  }, [isDone]);

  useEffect(() => {
    if (uid && !payable) {
      setPayable(true);
    } else if (!uid && payable) {
      setPayable(false);
    }
  }, [uid, payable])

  return (
    <div className="container mx-auto">
      <section className="grid place-content-center min-h-screen">

        <div className="rounded-md bg-white mx-4 mb-4">
          <div className="rounded-lg p-3" style={{ minWidth: 360 }}>
            <p className="text-gray-custom font-bold text-md pb-1">
              Peer2pay UID
            </p>
            <div className="grid grid-cols-3 gap-2 font-bold px-1">
              <input type="text" className="input input-bordered col-span-3 bg-white text-black" value={uid} onChange={e => setUid(e.target.value)} />
            </div>
          </div>
        </div>

        {
          <div className="rounded-md bg-white mx-4">
            <AmountForm requestPayment={setAmount} payable={payable} />
          </div>
        }

      </section>

    </div >
  );
};
export default Peer2pay;
