import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import QRCode from "../component/qrcode.component";
import AmountForm from "../component/amount.form.component";
import { requestPayment } from "../common/api/payment";
import loading from "../utils/loading";

import { Context, SET_VALUES } from "../store";
import { STATUS } from "../enum";
import P2PComponent from "../component/p2p.component";

const Form = () => {
  const navigate = useNavigate();

  const [amount, setAmount] = useState<"" | number>(0);
  const [isDone, setDone] = useState<boolean>(false);

  const [state, dispatch] = useContext(Context);


  /*
      สร้างรายการฝากเงิน
  */
  useEffect(() => {
    const reqeust = async () => {
      if (amount == "") return; // ค่าเริ่มต้น ไม่ต้องทำอะไร

      if (amount >= Number(localStorage.getItem("min")) && amount <= Number(localStorage.getItem("max"))) {
        // ถ้าใส่จำนวนเงินค่อยรัน

        setAmount(0); // rollback

        loading(); //swal loading

        try {
          let res = await requestPayment({
            amount: Number(amount),
          });

          dispatch({
            type: SET_VALUES,
            payload: {
              status: STATUS.PENDING,
              amount: Number(amount),
            },
          });

          localStorage.setItem("paymentInfo", JSON.stringify(res.data.data));

          Swal.close(); // close loading
        } catch (e: any) {
          Swal.fire({
            icon: "error",
            title: `ระบบเติมเงินมีปัญหา กรุณาทำรายการใหม่`,
            text: `[${localStorage.getItem("x-debug-key")}]${e.message}` || "",
            showConfirmButton: false,
            timer: 3500,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: `กรุณากรอกจำนวนเงิน ${localStorage.getItem("min")} - ${localStorage.getItem("max")} บาท`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };

    reqeust()
      .then()
      .catch((e) => {
        console.error(e);
      });
  }, [amount]);

  /*
    เช็คว่าการเติมเงินเสร็จสิ้นหรือยัง
  */
  useEffect(() => {
    if (isDone) {
      navigate("/success");
    }
  }, [isDone]);

  return (
    <div className="container mx-auto">
      <section className="grid place-content-center min-h-screen">
        {
          state.paymentMethod === 'P2P'
            ?
            <div className="rounded-md bg-white mx-4 my-2">
              <div className="p-4">
                <h1 className="text-red-600 text-xl font-bold">คำเตือน！</h1>
                <p className="text-center">
                  ยอดเงินนี้ สามารถโอนจ่ายได้เพียงครั้งเดียวเท่านั้น
                </p>
              </div>
            </div>
            :
            <div className="rounded-md bg-white mx-4 my-2">
              <div className="p-4">
                <h1 className="text-red-600 text-xl font-bold">คำเตือน！</h1>
                <p className="text-center">
                  QR Code สามารถสแกนจ่ายได้เพียงครั้งเดียวเท่านั้น
                </p>
              </div>
            </div>
        }

        {state.status == STATUS.PENDING &&
          <div className="rounded-md bg-white m-4">
            {
              state.paymentMethod === 'P2P'
                ? <P2PComponent />
                : <QRCode />
            }
          </div>
        }

        <div className="rounded-md bg-white mx-4 ">
          <AmountForm requestPayment={setAmount} />
        </div>

      </section>

    </div >
  );
};
export default Form;
