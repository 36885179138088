import { useContext, useEffect } from "react";
import { Context } from "../store";

const Invalid = () => {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="container mx-auto text-center">
      <section className="grid place-content-center h-screen">
        <div>
          <img
            className="object-contain mx-auto"
            src="/static/logo/thaiQR.png"
            alt="logo"
          />
        </div>
        <div className="text-black">
          <h1 className="font-bold text-2xl">พบปัญหาในการฝากเงิน</h1>
          <span className="text-sm text-black/50">
            หากท่านทำรายการแล้วแต่ยอดเงินไม่เข้า กรุณาติดต่อ พนักงาน
          </span>
        </div>
      </section>
    </div>
  );
};
export default Invalid;
