import { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Context } from "../store";
import { ExternalLink } from "react-external-link";

const Success = () => {
  const [state, dispatch] = useContext(Context);

  const [redirectUrl, setRedirect] = useState<string>("");

  /*
    ลบ hash เพื่อป้องกันให้กลับไปขอ qr ใหม่
  */
  useEffect(() => {
    setRedirect(state.meta.resultURL);
    setTimeout(() => {
      window.location.href = state.meta.resultURL;
    },3000);
    localStorage.clear();
  });

  return (
    <div className="container mx-auto text-center">
      <div className="pt-[69px]">
        <img
          className="object-contain h-32 mx-auto"
          src="https://miro.medium.com/max/1400/1*nueyBV0RNEpETYMKpsYWhA.png"
          alt="true-money-wallet-logo"
        />
      </div>
      <div className="text-black font-semibold text-[22px] pt-8">
        ทำรายการสำเร็จ
      </div>
      <div className="max-w-[315px] mx-auto font-normal pt-3">
        เราได้รับการฝากเงินของท่านแล้ว กรุณารอไม่เกิน 1 นาที
        ยอดเงินจะเข้าสู่บัญชีของท่าน
      </div>
      {redirectUrl && (
        <div className="w-full pt-8">
          <div className="flex flex-col items-center px-4">
            <ExternalLink
              href={redirectUrl}
              target="_self"
              className="py-4 w-full md:w-1/2 bg-primary-orange text-white font-semibold rounded-lg"
            >
              กลับสู่หน้าเว็บ
            </ExternalLink>
          </div>
        </div>
      )}
    </div>
  );
};
export default Success;
