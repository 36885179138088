import { useContext } from "react";

import { Navigate } from "react-router-dom";
import { Context } from "./store";
import { STATUS } from "./enum";
import Form from "./page/form.component";
import Swal from "sweetalert2";
import Success from "./page/success.component";
import Timeout from "./page/timeout.component";
import Invalid from "./page/invalid.component";
import Peer2pay from "./page/peer2pay.component";

const Protected = () => {
  const [state, dispatch] = useContext(Context);

  let redirectUrl = "/404";

  try {
    switch (state.status) {
      case STATUS.WAIT:
        if (localStorage.getItem("provider") === 'peer2pay') {
          return <Peer2pay />
        }
        return <Form />;
      case STATUS.PENDING:
        return <Form />;
      case STATUS.SUCCESS:
        return <Success />;
      case STATUS.TIMEOUT:
        return <Timeout />;
      default:
        return <Invalid />;
    }
  } catch (e: any) {
    localStorage.clear();

    (async () => {
      await Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: e.message || "[invalid token] ติดต่อพนักงาน",
      });
      window.location.href = redirectUrl;
    })();
    return <Navigate to={redirectUrl}></Navigate>;
  }
};

export default Protected;
