import Swal from "sweetalert2";

const loading = () => Swal.fire({
  title: 'Loading...',
  allowOutsideClick: false,
  allowEscapeKey: false,
  allowEnterKey: false,
  showConfirmButton: false,
  willOpen: () => {
      Swal.showLoading()
  },
});

export default loading;