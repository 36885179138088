import axios from "axios";

export const requestPayment = async (payload: {
  amount: number;
  uid?: string;
}) => {

  const url = `${process.env.REACT_APP_API_BASE}api/payment` || "/api/payment";
  return axios.post(
    url,
    {
      ...payload,
      token: localStorage.getItem("hash") as String,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "x-debug-key": localStorage.getItem("x-debug-key") as string,
      },
    }
  );
};

export const checkPayment = async (payload: { id: string }) => {
  const url =
    `${process.env.REACT_APP_API_BASE}api/payment/${payload.id}` ||
    `/api/payment/${payload.id}`;

  return axios.post(url, payload, {
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("hash") ?? "",
    },
  });
};
