import { useContext, useEffect, useState, useRef } from "react";
import { checkPayment } from "../common/api/payment";
import Swal from "sweetalert2";
import { Context, SET_VALUES } from "../store";
import Countdown, { zeroPad } from "react-countdown";
import { STATUS } from "../enum";
import dayjs from "dayjs";

import downloadjs from "downloadjs";
import html2canvas from "html2canvas";

import "./qrcode.style.scss";
import { AxiosError } from "axios";

import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)


const P2PComponent = () => {
  const [isChecking, setChecking] = useState<boolean>(false);

  const [state, dispatch] = useContext(Context);

  const paymentInfo = JSON.parse(
    (localStorage.getItem("paymentInfo") as string) || "{}"
  );

  const isError = async () => {
    await Swal.fire({
      icon: "error",
      title: "เกิดข้อผิดพลาด ติดต่อเจ้าหน้าที่",
      showConfirmButton: false,
      timer: 1500,
    });
    window.location.href = "/";
  };

  const isExpire = async () => {
    await Swal.fire({
      icon: "error",
      title: "หมดเวลาการชำระเงิน",
      text: "หากท่านทำรายการแล้วแต่ยอดเงินไม่เข้า กรุณาติดต่อ พนักงาน",
      showConfirmButton: false,
      timer: 1500,
    });
    window.location.href = "/";
    localStorage.clear();
  };

  const countdownRendered = ({ completed }) => {

    if (completed) {
      isExpire();
    } else {
      (async () => {
        try {
          const response = await checkPayment({
            id: paymentInfo.id,
          });

          if (["successed", "sended"].includes(response.data.data.status)) {
            // รอทำ enum ถ้า ขยัน
            dispatch({
              type: SET_VALUES,
              payload: {
                status: STATUS.SUCCESS,
              },
            });
          }
        } catch (e: any) {
          if (e instanceof AxiosError) {
            /* ปิด error ตอน download ไฟล์ */
            if (e.response?.status as number > 0) {
              isError();
            }
          }
        }
      })();

      return dayjs(paymentInfo.expiredDate)
        .format(" วันที่ DD เวลา HH:mm น")
        .toString();
    }
  };

  const P2PDisplay = ({ data }) => {

    const showWarning = async () => {
      const idiotTime = localStorage.getItem("idiotTime");

      if (idiotTime == null || Number(idiotTime) < Math.round(new Date().getTime() / 1000)) {


        let { isDenied, isConfirmed } = await MySwal.fire({
          icon: 'warning',
          title: `ห้ามใช้ ยอดเดิม ฝากเงินซ้ำ`,
          html: `หากลูกค้าใช้ ยอดเดิม (${data.payAmount}) ฝากเงินซ้ำ <br> จะเกิดปัญหา ฝากเงินช้า หรือ ฝากไม่เข้า`,
          showDenyButton: true,
          confirmButtonText: `ฉันไม่เข้าใจ`,
          denyButtonText: `ฉันเข้าใจ`
        });

        if (isConfirmed === true) {
          await showWarning();
        }

        localStorage.setItem("idiotTime", (Math.round((new Date().getTime() / 1000) + 600)).toString());

      }
    }

    (async () => {
      await showWarning();
    })();

    return (
      <div>
        <div className="text-center" >
          <div>
            <p className="text-gray-custom text-xs">
              {data.merchantRef} [{data.payAmount}]
            </p>
          </div>

          <div className="pt-1">
            <p className="text-red-600 font-bold blink">
              ห้ามใช้ ยอดเดิม จ่ายเงินซ้ำ
            </p>
          </div>
          <div className="pt-4">
            <div className="w-full">
              <div className="mb-4 mx-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left" >
                  ธนาคาร
                </label>
                <span className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight w-full">{data.payeeBank}</span>
              </div>
              <div className="mb-4 mx-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left" >
                  เลขบัญชี
                </label>
                <span className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight w-full">
                  {data.payeeAccount.substring(0, 3)}-{data.payeeAccount.substring(3, 4)}-{data.payeeAccount.substring(4, 9)}-{data.payeeAccount.substring(9)}
                </span>
              </div>
              <div className="mb-4 mx-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left" >
                  ชื่อ
                </label>
                <span className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight w-full">{data.payee}</span>
              </div>
              <div className="mb-4 mx-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left" >
                  ยอดโอน
                </label>
                <span className="text-6xl block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight w-full">{data.payAmount}</span>
              </div>
            </div>
          </div>

          <div className="pb-1">
            <p className="text-gray-custom mt-3"> ห้ามทำรายการหลังจากเวลา </p>
            <p className="text-red-600 font-bold blink">
              <Countdown
                zeroPadTime={2}
                date={data.expiredDate}
                renderer={countdownRendered}
              />
            </p>
          </div>
        </div>

        {/* <div className="mx-3 mb-4 mt-2">
          <button
            type="button"
            className="w-full py-4 border-2 border-blue-custom font-semibold rounded-lg"
          >
            กดเพื่อ แคปจอ
          </button>
        </div> */}
      </div>
    );
  };

  return (
    <div className="mx-auto w-96 rounded-lg" id="qr-card">
      <div className="mx-auto text-center">
        <P2PDisplay data={paymentInfo} />
      </div>
    </div>
  );
};

export default P2PComponent;
